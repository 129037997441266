import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.scss'],
})
export class RedirectPageComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  id: string = '';

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    window.location.href = 'https://api.zelo.no/v1/url/' + this.id;
  }
}
